.login-page {
    .ant-form.ant-form-horizontal.login-form {
        max-width: 300px;
        margin: 0 auto;
    }
    
    .login-form-button {
        width: 100%;
    }
}
