.property {
    display: inline-block;
    text-align: center;

    i {
        font-size: 64px;
    }

    .content {
        color: #000000; 

        address,
        p {
            margin: 0;
        }

        .property-address {
            font-size: 16px;
            font-weight: bold;
        }
    }
}